import React, { Component } from 'react';

class Hurry extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bg-light my-5">
                	<div className="container">
                		<div className="row align-items-center">
                			<div className="col-12 col-lg-5 pt-3 order-2 order-lg-1 text-center">
                				<h2><b>Warte nicht länger!</b></h2> 
                                <p className="mb-5">Hol dir jetzt Dein persönliches Angebot!</p>
                                <button className="btn btn-primary btn-lg text-uppercase" onClick={this.props.handleClick}>Jetzt Angebot einholen!</button>
                                
                			</div>
                			<div className="col-12 col-lg-7 order-1 order-lg-2 text-center pt-4">
                                <img className="img-fluid" src={require('../../static/hurry.png')} alt="wirkaufendeinenshop.de - Jetzt Angebot sichern!" />
                			</div>
                			
                		</div>
                	</div>
                </div>
            </React.Fragment>
        )
    }
}
export default Hurry;

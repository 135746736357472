import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';



class Header extends Component {

  constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

    render() {
        return (
            <header className="border-bottom py-0 py-md-2">
                <nav className="navbar navbar-expand-lg navbar-light container">
                  <a className="navbar-brand d-flex text-uppercase" href="/" id="navbar-brand">
                    <img src="/img/logo.png" width="32" height="32" className="mr-2" alt="wirkaufendeinenshop.de - Logo" />
                    <div className="d-none d-sm-flex">
                      <span className="small d-flex text-primary align-items-end">wir</span>
                      <span className="small d-flex align-items-end">kaufen</span>
                      <span className="small d-flex align-items-end text-primary">deinen</span>
                      <span className="small d-flex align-items-end">shop</span>
                      <span className="small d-flex text-primary align-items-end">.de</span>
                    </div>
                    <div className="d-flex d-sm-none" style={{fontSize: '60%'}}>
                      <span className="d-flex text-primary align-items-center">wir</span>
                      <span className="d-flex align-items-center">kaufen</span>
                      <span className="d-flex align-items-center text-primary">deinen</span>
                      <span className="d-flex align-items-center">shop</span>
                      <span className="d-flex text-primary align-items-center">.de</span>
                    </div>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                    onClick={() => this.handleClick()}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className={`collapse navbar-collapse ${this.state.open ? 'show': ''}`} id="navbarSupportedContent">
                    <div className="d-block d-lg-none border-top row mt-3 mb-3" />
                    <ul className="navbar-nav ml-auto small">
                      <li className="nav-item">
                        <a className="nav-link text-primary" href="/agb">AGB <span className="sr-only">(current)</span></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-primary" href="/datenschutz">Datenschutz</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-primary" href="/impressum">Impressum</a>
                      </li>
                    </ul>
                  </div>
                </nav>
            </header>
        );
    }
}

export default Header;

import React, { Component } from 'react';

class Rating extends Component {

    render() {
        return (
            <div className="py-5">
                <div className="container">
                    <h3  className="text-center mb-5">So zufrieden sind unsere Kunden</h3>
                    <div className="text-center">
                        <a href="https://www.provenexpert.com/abfindungsheld-de" target="_blank" rel="noopener noreferrer">
                            <img 
                                className="pex-logo" 
                                alt="proven-expert-logo" 
                                src="https://d2ccrzxhvsclcm.cloudfront.net/assets/1.9.2/recommendations/provenexpert-logo@1x-7c78680b10c7cd5ecf02114aa79f7201.png" 
                                srcSet="https://d2ccrzxhvsclcm.cloudfront.net/assets/1.9.2/recommendations/provenexpert-logo@2x-bc9df0f37a0df69e16c409b3e2db4e92.png 2x, https://d2ccrzxhvsclcm.cloudfront.net/assets/1.9.2/recommendations/provenexpert-logo@3x-1a331fda7c2873b280e07f176152d5e5.png 3x"
                            />
                        </a>
                    </div>
                    <div className="text-center mt-2">
                        <svg 
                            version="1.1" 
                            id="Star" 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            x="0px" y="0px"
                            viewBox="0 0 100 95.4" 
                            xmlSpace="preserve"
                            style={{ maxWidth: '36px', height: 'auto', fill: 'rgb(0, 122, 207)' }}
                            >
                                <polygon points="75.6,61.5 97.5,37.5 66.2,31 50.1,2.8 34.3,30.6 2.5,37.1 24.1,60.7 20.4,93 49.6,79.8 79.1,93.2 "/>
                        </svg>
                        <svg 
                            version="1.1" 
                            id="Star" 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            x="0px" y="0px"
                            viewBox="0 0 100 95.4" 
                            xmlSpace="preserve"
                            style={{ maxWidth: '36px', height: 'auto', fill: 'rgb(0, 122, 207)' }}
                            >
                                <polygon points="75.6,61.5 97.5,37.5 66.2,31 50.1,2.8 34.3,30.6 2.5,37.1 24.1,60.7 20.4,93 49.6,79.8 79.1,93.2 "/>
                        </svg>
                        <svg 
                            version="1.1" 
                            id="Star" 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            x="0px" y="0px"
                            viewBox="0 0 100 95.4" 
                            xmlSpace="preserve"
                            style={{ maxWidth: '36px', height: 'auto', fill: 'rgb(0, 122, 207)' }}
                            >
                                <polygon points="75.6,61.5 97.5,37.5 66.2,31 50.1,2.8 34.3,30.6 2.5,37.1 24.1,60.7 20.4,93 49.6,79.8 79.1,93.2 "/>
                        </svg>
                        <svg 
                            version="1.1" 
                            id="Star" 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            x="0px" y="0px"
                            viewBox="0 0 100 95.4" 
                            xmlSpace="preserve"
                            style={{ maxWidth: '36px', height: 'auto', fill: 'rgb(0, 122, 207)' }}
                            >
                                <polygon points="75.6,61.5 97.5,37.5 66.2,31 50.1,2.8 34.3,30.6 2.5,37.1 24.1,60.7 20.4,93 49.6,79.8 79.1,93.2 "/>
                        </svg>
                        <svg 
                            version="1.1" 
                            id="Star" 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            x="0px" y="0px"
                            viewBox="0 0 100 95.4" 
                            xmlSpace="preserve"
                            style={{ maxWidth: '36px', height: 'auto', fill: 'rgb(0, 122, 207)' }}
                            >
                                <polygon points="75.6,61.5 97.5,37.5 66.2,31 50.1,2.8 34.3,30.6 2.5,37.1 24.1,60.7 20.4,93 49.6,79.8 79.1,93.2 "/>
                        </svg>
                    </div>
                    <div className="text-center mt-2" style={{ fontSize: 'x-large' }}>
                        Kundenbewertung <span className="text-primary"><b>(5 / 5)</b></span>
                    </div>
                </div>
            </div>
        )
    }
}
export default Rating;
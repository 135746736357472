import React, { Component } from 'react';

class ImprintPage extends Component {

    render() {
        return (
        	<div id="imprint-page" className="py-5 container">
        		<h2>Impressum</h2>
        		<p>
        			<b>Angaben gemäss § 5 TMG:</b><br />
        			www.wirkaufendeinenshop.de<br />
					wird betrieben von:<br />
					<br />
					CC CasinoChecker GmbH<br />
					Weydingerstrasse 14-16<br />
					10178 Berlin<br />
					Deutschland<br />
				</p>
				<p>
					<b>Vertreten durch:</b><br />
					Bernd Henning
				</p>
				<p>
					<b>Kontakt:</b><br />
					Telefon: +49 30 12059666<br />
					info@wirkaufendeinenshop.de<br />
				</p>
				<p>
					<b>Registereintrag:</b><br />
					<table>
						<tr>
							<td>Registergericht:</td><td>Amtsgericht Charlottenburg</td>
						</tr>
						<tr>
							<td>Registernummer:</td><td>HRB 194661 B</td>
						</tr>
						<tr>
							<td>Steuernummer:</td><td>30 / 249 / 51226</td>
						</tr>
					</table>
				</p>
				<p>
					<b>Verantwortlich gem. § 55 RStV für eigene Inhalte der www.wirkaufendeinenshop.de:</b><br />
					CC CasinoChecker GmbH, Bernd Henning
				</p>
				<p>
					<b>Streitschlichtung:</b><br />
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
				</p>
				<p>
					<b>Haftung für Inhalte:</b><br />
					Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
					Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu 
					überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br />
					<br />
					Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. 
					Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden 
					von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
				</p>
				<p>
					<b>Haftung für Links:</b>
					Unser Angebot kann Links zu externen Websites Dritter enthalten, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für 
					diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber 
					der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige 
					Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br />
					<br />
					Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden 
					Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir umgehend 
					um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
				</p>
        	</div>  
		);
    }
}

export default ImprintPage;

import React, { Component } from 'react';
import { isValidEmail } from '../../../utils';


class Step7 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: props.store.firstname,
      lastname: props.store.lastname,
      phone: props.store.phone,
      email: props.store.email,
      touchedFields: {},
    };

    this.save = this.save.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.touchField = this.touchField.bind(this);
  }

  getErrors() {
    const {
        firstname, lastname, phone, email, touchedFields,
    } = this.state;
    const errors = {};


    if (touchedFields.firstname) {
      if (!firstname) {
        errors.firstname = <div className="invalid-feedback"><b>Vorname</b> ist ein Pflichtfeld.</div>;
      }
    }

    if (touchedFields.lastname) {
      if (!lastname) {
        errors.lastname = <div className="invalid-feedback"><b>Nachname</b> ist ein Pflichtfeld.</div>;
      }
    }

    if (touchedFields.phone) {
      if (!phone) {
        errors.phone = <div className="invalid-feedback"><b>Telefonnummer</b> ist ein Pflichtfeld.</div>;
      }
    }

    if (touchedFields.email) {
      if (!email) {
        errors.email = <div className="invalid-feedback">Wir benötigen deine E-Mail Adresse.</div>;
      } else if (!isValidEmail(email)) {
        errors.email = <div className="invalid-feedback">E-Mail Adresse ist nicht korrekt.</div>;
      }
    }


    return errors;
  }

  isValidated() {
    return new Promise((resolve, reject) => this.setState({
      touchedFields: {
        firstname: true,
        lastname: true,
        phone: true,
        email: true,
      },
    }, () => {
      const errors  = this.getErrors();
      return Object.keys(errors).length === 0 ? resolve() : reject();
    }));
  }

  touchField(e) {
    const { name } = e.target;
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [name]: true,
      },
    });
  }

  save(e)  {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.updateStore({ [name]: value }));
  }

  render() {
    const {
      firstname, lastname, phone, email,
    } = this.state;

    const errors = this.getErrors();

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Wo können wir Dich für ein Angebot erreichen?</h4>
        <div className="d-flex justify-content-around row">
          <div className="d-flex flex-column col-12 col-lg-6">
            <label className="py-3">
              <input
                type="text"
                className={`form-control ${errors.firstname ? 'is-invalid' : ''}`}
                name="firstname"
                placeholder="Vorname"
                defaultValue={firstname}
                onChange={this.save}
                onBlur={this.touchField}
              />
              {errors.firstname && <div className="invalid-feedback">{errors.firstname}</div>}
            </label>
            <label className="py-3">
              <input
                type="text"
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                name="phone"
                placeholder="Telefonnummer"
                defaultValue={phone}
                onChange={this.save}
                onBlur={this.touchField}
              />
              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </label>
          </div>
          <div className="d-flex flex-column col-12 col-lg-6">
            <label className="py-3">
              <input
                type="text"
                className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                name="lastname"
                placeholder="Nachname"
                defaultValue={lastname}
                onChange={this.save}
                onBlur={this.touchField}
              />
              {errors.lastname && <div className="invalid-feedback">{errors.lastname}</div>}
            </label>
            <label className="py-3">
              <input
                type="text"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                name="email"
                placeholder="E-Mail Adresse"
                defaultValue={email}
                onChange={this.save}
                onBlur={this.touchField}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default Step7;

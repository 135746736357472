import React, { Component } from 'react';

class Seals extends Component {
    render() {
        return (
            <div className="d-flex justify-content-center pb-5 container align-items-center">
            	<img src={require('../../static/trust-seal.png')} alt="100% Kundenzufriedenheit" className="mx-3" width="78" />
                <img src={require('../../static/proven-expert-seal.png')} alt="Proven expert" className="mx-3" width="78" />
                <img src={require('../../static/ssl-seal.png')} alt="SSL Secured" className="mx-3" width="58" />
            </div>
        )
    }
}
export default Seals;
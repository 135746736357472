import React, { Component } from 'react';

class Convince extends Component {

    render() {
        return (
            <div className="bg-light">
            	<div className="container">
            		<div className="row align-items-center">
            			<div className="col-12 col-lg-5 pt-3 order-2 order-lg-1 align-self-end">
            				<img className="img-fluid" src={require('../../static/convince-guy.png')} alt="Nicht länger warten - jetzt Angebot sichern" />
            			</div>
            			<div className="col-12 col-lg-7 order-1 order-lg-2 py-5 py-xl-0">
            				<p><b>wirkaufendeinenshop.de - Dein Partner beim Wettshop Verkauf</b></p>
            				<p>
            					Mit wirkaufendeinenshop.de hast Du den perfekten Ansprechpartner für Deinen Wettshop Verkauf gefunden. 
            					Unsere Experten möchten den Verkauf Deines Shops so schnell und unkompliziert wie möglich für Dich gestalten. 
            				</p>
            				<p>
    							Prüfe einfach in nur 2 Minuten online den Wert Deines Wettshops und vereinbare einen Vor Ort Termin mit einem 
    							unserer Experten. 
    						</p>
    						<p>
    							Unsere Shopexperten vor Ort begutachten Deinen Wettshop ganz individuell. Danach teilen sie Dir Deine verfügbaren 
    							Vermarktungsoptionen mit.
    						</p>
    						<p className="p-0 m-0">
    							Der Verkauf Deines Wettshops war noch nie so simpel, das bestätigen die vielen positiven Bewertungen unserer Kunden.
    						</p>
            			</div>
            			
            		</div>
            	</div>
            </div>
        )
    }
}
export default Convince;

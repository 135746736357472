import React, { Component } from 'react';
import { Style } from 'radium';
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './container/MainPage';


const styles = {
	fontFamily: 'Roboto, sans-serif',
	color: '#4a4a4a',
}


class App extends Component {
  render() {
    return (
			<div className="App">
				<Style scopeSelector=".App" rules={styles} />
				<div className="d-flex flex-column">
					<Header />
					<MainPage />
					<Footer />
				</div>
			</div>
    );
  }
}


export default App;

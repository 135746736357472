import React, { PureComponent } from 'react';

const FIELD_NAME = 'contract';

class Step1 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { currentValue: props.store[FIELD_NAME] };

    this.save = this.save.bind(this);
  }

  save(e)  {
    const { value } = e.target;

    this.setState({ currentValue: value }, () => {
      this.props.updateStore({ [FIELD_NAME]: value });
      this.props.jumpToStep(2);
    });
  }

  render() {
    const { currentValue } = this.state;

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Wie ist die Vertragssituation für Deinen Shop?</h4>
        <div className="d-flex justify-content-around row">
          <div className="d-flex flex-column col">
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Mietvertrag"
                defaultChecked={currentValue === 'Mietvertrag'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Mietvertrag</div>
            </label>
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Eigentum"
                defaultChecked={currentValue === 'Eigentum'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Eigentum</div>
            </label>
          </div>
          <div className="d-flex flex-column col">
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Grundstück"
                defaultChecked={currentValue === 'Grundstück'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Grundstück</div>
            </label>
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Neuobjekt"
                defaultChecked={currentValue === 'Neuobjekt'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Neuobjekt</div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default Step1;

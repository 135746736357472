import React, { PureComponent } from 'react';

const FIELD_NAME = 'type';

class Step0 extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { currentValue: props.store[FIELD_NAME] };

		this.save = this.save.bind(this);
	}

	save(e)  {
		const { value } = e.target;

		this.setState({ currentValue: value }, () => {
			this.props.updateStore({ [FIELD_NAME]: value });
			this.props.jumpToStep(1);
		});
	}

	render() {
		const { currentValue } = this.state;

		return (
			<div className="d-flex flex-column flex-grow-1 step">
				<h4 className="text-center mb-4 mt-2">Welchen Shop möchtest Du verkaufen?</h4>
				<div className="d-flex justify-content-around row">
					<div className="d-flex flex-column col">
						<label className="p-3 radio">
							<input
								type="radio"
								name={FIELD_NAME}
								onChange={this.save}
								value="Sportwetten Shop"
								defaultChecked={currentValue === 'Sportwetten Shop'}
							/>
							<div className="px-3 py-2 rounded bg-white border text-center">Sportwetten Shop</div>
						</label>
						<label className="p-3 radio">
							<input
								type="radio"
								name={FIELD_NAME}
								onChange={this.save}
								value="Spielhalle"
								defaultChecked={currentValue === 'Spielhalle'}
							/>
							<div className="px-3 py-2 rounded bg-white border text-center">Spielhalle</div>
						</label>
					</div>
					<div className="d-flex flex-column col">
						<label className="p-3 radio">
							<input
								type="radio"
								name={FIELD_NAME}
								onChange={this.save}
								value="Sportsbar mit Automaten"
								defaultChecked={currentValue === 'Sportsbar mit Automaten'}
							/>
							<div className="px-3 py-2 rounded bg-white border text-center">Sportsbar mit Automaten</div>
						</label>
						<label className="p-3 radio">
							<input
								type="radio"
								name={FIELD_NAME}
								onChange={this.save}
								value="Anderer Shop"
								defaultChecked={currentValue === 'Anderer Shop'}
							/>
							<div className="px-3 py-2 rounded bg-white border text-center">Anderer Shop</div>
						</label>
					</div>
				</div>
			</div>
		);
	}
}

export default Step0;

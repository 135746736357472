import React, { PureComponent } from 'react';

const FIELD_NAME = 'location';

class Step2 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { currentValue: props.store[FIELD_NAME] };

    this.save = this.save.bind(this);
  }

  save(e)  {
    const { value } = e.target;
    this.setState({ selectedOption: value }, () => {
      this.props.updateStore({ [FIELD_NAME]: value });
      this.props.jumpToStep(3);
    });
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Wie ist die Gebietsart an dem Ort des Shops?</h4>
        <div className="d-flex justify-content-around row">
          <div className="d-flex flex-column col">
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Mischgebiet"
                defaultChecked={selectedOption === 'Mischgebiet'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Mischgebiet</div>
            </label>
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Gewerbegebiet"
                defaultChecked={selectedOption === 'Gewerbegebiet'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Gewerbegebiet</div>
            </label>
          </div>
          <div className="d-flex flex-column col">
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Kerngebiet"
                defaultChecked={selectedOption === 'Kerngebiet'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Kerngebiet</div>
            </label>
            <label className="p-3 radio">
              <input
                type="radio"
                name={FIELD_NAME}
                onChange={this.save}
                value="Andere"
                defaultChecked={selectedOption === 'Andere'}
              />
              <div className="px-3 py-2 rounded bg-white border text-center">Andere</div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;

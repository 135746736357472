import React, { Component } from 'react';
import ReactGA from 'react-ga';


class Step8 extends Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitted: false };

    this.submitData = this.submitData.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.isValidated = this.isValidated.bind(this);
  }

  componentDidMount() {
    this.submitData();
  }

  submitData() {
    ReactGA.event({
      category: 'User',
      action: 'conversion',
      label: 'User Conversion'
    });
    const xhr = new XMLHttpRequest();
    const url = '/api/api.php';
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.status, xhr.responseText);
        this.setState({ isSubmitted: true });
      }
    };

    const store = this.props.store;
    const data = JSON.stringify(store);
    xhr.send(data);
  }

  handleClick() {
    this.props.resetWizard();
  }

  isValidated() {
    return this.state.isSubmitted;
  }

  render() {
    const { isSubmitted } = this.state;
    if(isSubmitted) { window.sessionStorage.clear(); }

    return (
      <div className="step">
        {isSubmitted ? (
          <div className="text-center">
            <img src={require('../../../static/check.svg')} className="img-fluid" style={{width: '200px'}} alt="" />
            <h1>Vielen Dank!</h1>

            <p>Wir melden uns innerhalb der nächsten 2 Werktage.</p>
            <div className="mt-5">
              <button className="btn btn-lg btn-primary" onClick={this.handleClick}>Schließen</button>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

export default Step8;

import React, { Component } from 'react';

class TermsPage extends Component {

    render() {
        return (
          	<div id="terms-page" className="py-5 container">
          		<h2>AGB - Allgemeine Geschäftsbedingungen</h2>
          		<p>
          			<b>
          				AGB - Allgemeine Geschäftsbedingungen für die Nutzung der Serviceleistungen und 
          				Webseiten www.wirkaufendeinenshop.de   der CC CasinoChecker GmbH
          			</b>
          		</p>
                    <p>
          			Wir bitten Sie diese Allgemeinen Geschäfts-Bedingungen (AGB) aufmerksam durchzulesen, 
          			bevor Sie die Internetseiten und Dienstleistungen der CC CASINOCHECKER GmbH nutzen. 
          			Mit der Nutzung unserer Webseiten bzw. unserer Dienstleistungen erklären Sie Ihr 
          			Einverständnis an unseren Bedingungen.
          		</p>

          		<ol>
          			<li>
          				<p>
          					<b>Allgemeine Bedingungen und Vertragsgegenstand</b><br />
          					<ol>
          						<li>
          							Die vorliegenden Allgemeinen Geschäftsbedingungen (nachstehend “AGB”) gelten 
          							für die Nutzung unserer Webseiten und unserer Dienstleistungen.
          						</li>
          						<li>
          							Die Internetseiten wirkaufendeinenshop.de / wirkaufendeinenshop.de / wirkaufendeineshops.de 
          							sind Informationen und Dienstleistungen, die von der CC CASINOCHECKER GmbH mit Sitz in der 
          							Weydingerstrasse 14-16, 10178 Berlin (im folgenden “CC” oder “wir”) betrieben werden. Mit 
          							diesen AGB und den Inhalten der Webseiten informieren wir Besucher bzw. Nutzer über unsere 
          							Webseiten, Dienstleistungen und Bedingungen.
          						</li>
          						<li>
          							Unsere Webseiten ermöglichen den Nutzern bestimmte Shop- oder Spielhallen-Daten in eine Abfrage 
          							einzugeben, um eine vorläufige Bewertung eines Shops oder Spielhalle, oder ggf. Kaufangebot 
          							per email zu erhalten. Die vorläufige Bewertung ist kostenlos. Sollten wir für Sie ein Lead 
          							zum Verkauf bzw. Ankauf Ihres Shop oder Spielhalle erstellen, richten sich die Gebühren nach 
          							einem gesondert abzuschließenden Leadgenerierungsvertrag, basierend auf den Daten die der Nutzer 
          							uns zur Verfügung stellt. 
          						</li>
          						<li>
          							Durch Eingabe Ihrer Daten über unsere Webseiten, erlauben Sie uns diese Daten zu nutzen um Ihnen 
          							eine kostenlose Bewertung oder Kaufangebot per email zukommen zu lassen. Eine Verpflichtung zur 
          							Erstellung eines Angebotes und oder ein Kaufvertrag auf Basis dieser Daten oder unseres Angebotes 
          							kommt dadurch noch nicht zustande. Dies bedeutet, dass die Bewertung über unsere Webseiten oder 
          							die Übermittlung von weitergehenden Informationen weder ein verbindliches Kaufangebot der CC, 
          							noch ein verbindliches Verkaufsangebot des Nutzers für einen Shop oder Spielhalle darstellen.
          						</li>
          						<li>
          							Bei Problemen mit den Webseiten kann der Nutzer unseren Kundendienst über info@wirkaufendeinenshop.de 
          							(siehe Impressum) kontaktieren.
          						</li>
          						<li>
          							CC übernimmt keine Gewährleistung für fehlerfreie Wiedergabe oder Aufbereitung der vom Nutzer 
          							eingegebenen Daten, für technische Mängel der Webseiten oder Verlinkungen, sowie für die ständige 
          							und ununterbrochene Verfügbarkeit der Webseiten.
          						</li>
          					</ol>
          				</p>
          			</li>
          			<li>
          				<p>
          					<b>Urheberrechte und Nutzungsrechte</b><br />
          					<ol>
          						<li>
          							Nutzer unserer Webseiten, Dienstleistungen, Marken, Bilder, Logos sind nicht zur weiteren, insbesondere 
          							gewerblichen Nutzung dieser Informationen berechtigt. Weiterverarbeitung, oder Nutzung in Medien, Social 
          							Media, Blogs und Foren aller Art ist ohne vorherige, schriftliche Genehmigung der CC CasinoChecker GmbH 
          							nicht gestattet. Die Veränderung oder Beeinflussung unserer Webseiten ist grundsätzlich nicht gestattet 
          							und wird rechtlich verfolgt.
          						</li>
          					</ol>
          				</p>
          			</li>
          			<li>
          				<p>
          					<b>Haftungsbeschränkung</b>
          					<ol>
          						<li>
          							Nutzer haben keinen Anspruch auf unterbrechungsfreie Nutzung unserer Webseiten und Dienstleistungen. CC 
          							CASINOCHECKER GmbH übernimmt ferner keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der 
          							bereitgestellten Inhalte oder Dienste. Die Nutzung der abrufbaren Inhalte erfolgt auf eigene Gefahr des 
          							Nutzers. CC ist stets bemüht sicherzustellen, dass die Dienste von CC ohne Unterbrechungen verfügbar und 
          							fehlerfrei sind. Durch die Beschaffenheit des Internets kann dies jedoch nicht garantiert werden. Der 
          							Zugriff auf unsere Webseiten kann zur Instandsetzungen, Wartungen oder der Einführung von neuen Diensten 
          							unterbrochen oder beschränkt sein. Wir versuchen die Häufigkeit und Dauer jeder dieser vorübergehenden 
          							Unterbrechungen oder Beschränkungen zu begrenzen.
          						</li>
          						<li>
          							In Fällen leicht fahrlässiger Verletzung von nur unwesentlichen Vertragspflichten haftet die CC 
          							CASINOCHECKER GmbH nicht. Im Übrigen ist die Haftung der CC CASINOCHECKER GmbH für leicht fahrlässig 
          							verursachte Schäden auf diejenigen beschränkt, mit deren Entstehung typischerweise gerechnet werden muss.
          						</li>
          						<li>
          							Soweit die Haftung von CC CASINOCHECKER GmbH ausgeschlossen oder beschränkt ist, gilt dies auch für die 
          							persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen. 
          						</li>
          						<li>
          							Ansprüche auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche aus der 
          							Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten 
          							(Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen 
          							Pflichtverletzung von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen beruhen. Wesentliche 
          							Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.
          						</li>
          					</ol>
          				</p>
          			</li>
          			<li>
          				<p>
          					<b>Datenschutz</b><br />
          					<ol>
          						<li>
          							Uns ist der Schutz personenbezogener Daten wichtig. Daher verpflichten wir uns, die gesetzlichen 
          							Datenschutzbestimmungen einzuhalten, insbesondere das Bundesdatenschutzgesetz (BDSG) zu beachten, 
          							um hinreichenden Schutz und Sicherheit der Nutzerdaten zu erreichen.
          						</li>
          						<li>
          							Weitere Informationen zur Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten finden 
          							sich in unseren Datenschutzbestimmungen.
          						</li>
          					</ol>
          				</p>
          			</li>
          			<li>
          				<p>
          					<b>Gerichtsstand</b>
          					<ol>
          						<li>
          							Das Recht der Bundesrepublik Deutschland gilt für sämtliche Rechtsgeschäfte und jedwede rechtlichen 
          							Beziehungen mit der CC CasinoChecker GmbH unter Ausschluss des UN-Kaufrecht sowie andere sonstige 
          							zwischenstaatliche Übereinkommen, auch nicht nach ihrer Übernahme in das deutsche Recht.
          						</li>
          						<li>
          							Als Gerichsstand wird der Geschäftssitz der CC CasinoChecker GmbH vereinbart. Dies gilt insbesondere 
          							im  Geschäftsverkehr mit Kaufleuten und mit juristischen Personen des öffentlichen Rechts für alle 
          							Rechtsstreitigkeiten über die Geschäftsbedingungen und unter deren Geltung geschlossenen Einzelverträge. 
          							Die Wahl an jedem anderen gesetzlichen Gerichtsstand zu klagen bleibt uns vorbehalten. Ein etwaiger 
          							ausschließlicher Gerichtsstand bleibt von vorstehender Regelung unberührt.
          						</li>
          					</ol>
          				</p>
          			</li>
          			<li>
          				<p>
          					<b>Salvatorische Klausel</b><br />
          					<ol>
          						<li>
          							Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in seinen übrigen Teilen verbindlich. 
          							Anstelle der unwirksamen Punkte treten, soweit vorhanden, die gesetzlichen Vorschriften. Soweit dies für 
          							eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam.
          						</li>
          					</ol>
          				</p>
          			</li>
          		</ol>
          		<p className="small">(Stand - Januar 2019)</p>

          	</div>  
        );
    }
}

export default TermsPage;

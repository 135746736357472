import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer>
             	
                <link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet" />
                <div className="bg-light py-3 small">
                    <div className="container">
                        <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">
                            <div className="text-muted">
                                Copyright &copy; 2018 wirkaufendeinenshop.de
                            </div>
                            <div>
                                <a className="text-primary mr-2 pr-2" href="/agb">AGB</a>
                                <a className="text-primary mx-2 px-2" href="/datenschutz">Datenschutz</a>
                                <a className="text-primary ml-2 pl-2" href="/impressum">Impressum</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

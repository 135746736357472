import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Style } from 'radium';
import HomePage from '../pages/HomePage';
import DatasecurityPage from '../pages/DatasecurityPage';
import ImprintPage from '../pages/ImprintPage';
import TermsPage from '../pages/TermsPage';
import ErrorPage from '../pages/ErrorPage';
import ReactGA from 'react-ga';



const styles = {
	'#background-block': {
		backgroundImage: "url('/img/background.png')",
		backgroundPosition: 'top center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
	},
}

ReactGA.initialize([{
	trackingId: 'UA-132169395-1',
  }], { debug: false });


class MainPage extends Component {
	render() {
		ReactGA.pageview(window.location.pathname + window.location.search);
		return (
			<div id="mainPage">
				<Style scopeSelector="#mainPage" rules={styles} />
				<BrowserRouter>
					<Switch>
						<Route path="/" exact component={HomePage} />
						<Route path="/datenschutz" exact component={DatasecurityPage} />
						<Route path="/impressum" exact component={ImprintPage} />
						<Route path="/agb" exact component={TermsPage} />
						<Route component={ErrorPage} />
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}


export default MainPage;

import React, { Component } from 'react';
import Wizard from '../components/wizard/Wizard';
import Checklist from '../components/partials/Checklist';
import Brands from '../components/partials/Brands';
import Rating from '../components/partials/Rating';
import Steplist from '../components/partials/Steplist';
import Convince from '../components/partials/Convince';
import ProvenExpert from '../components/partials/ProvenExpert';
import Seals from '../components/partials/Seals';
import Hurry from '../components/partials/Hurry';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = { showWizard: false };

        this.toggleWizard = this.toggleWizard.bind(this);
    }

    toggleWizard() {
        this.setState({showWizard: !this.state.showWizard})
    }
    render() {
        return (
        	<React.Fragment>
        		{this.state.showWizard && <Wizard toggleWizard={this.toggleWizard} />}
        		<div id="background-block">
	        		<div className="container">
			            <div className="d-flex flex-column py-3 py-md-3 justify-content-center align-items-center"  style={{minHeight: '70vh'}}>
							<div className="mx-auto text-center mb-2 mb-sm-4">
				            	<p className="mb-0 mb-sm-3 text-uppercase">
				            		<span className="text-primary">wir</span>
				            		<span>kaufen</span>
				            		<span className="text-primary">deinen</span>
				            		<span>shop</span>
				            		<span className="text-primary">.de</span>
				            	</p>
				            	<p className="mb-2" style={{fontSize: '1.8rem'}}>
				            		Dein Partner beim Shopverkauf
				          		</p>
				          		<p className="small" style={{fontSize: '1.25rem'}}>
				          			Jetzt Wettshop oder Spielhalle 100% kostenlos und unverbindlich bewerten lassen.
				          		</p>
				            </div>
							<h1 className="text-center mb-3 mt-0 mt-sm-2">Wettshop oder Spielhalle verkaufen?</h1>
							<div className="text-center d-flex col-12 col-md-6">
								<button className="btn btn-block btn-primary btn-lg m-2 px-3 px-md-5 py-3" onClick={this.toggleWizard}>Ja</button>
								<button className="btn btn-block btn-primary btn-lg m-2 px-3 px-md-5 py-3">Nein</button>
							</div>
						</div>
					</div>
				</div>
				<Checklist />
				<Brands />
				<Steplist />
				<Convince />
				<Rating />
            	<ProvenExpert />
            	<Hurry handleClick={this.toggleWizard} />
            	<Seals />

               	
            </React.Fragment>
        );
    }
}

export default HomePage;

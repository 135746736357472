import React, { Component } from 'react';

class ErrorPage extends Component {

    render() {
        return (
            <div id="error-page" className="py-5 container">
            	<h2>404 - Die Seite konnte nicht gefunden werden</h2>
           	</div>
        );
    }
}

export default ErrorPage;

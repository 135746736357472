import React, { PureComponent } from 'react';

const FIELD_NAME = 'permission';

class Step5 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { currentValue: props.store[FIELD_NAME] };

    this.save = this.save.bind(this);
  }

  save(e)  {
    const { value } = e.target;
    this.setState({ currentValue: value }, () => {
      this.props.updateStore({ [FIELD_NAME]: value });
      this.props.jumpToStep(6);
    });
  }

  render() {
    const { currentValue } = this.state;

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Hast Du eine Baugenehmigung für deinen Shop?</h4>
          <div className="d-flex justify-content-around row">
            <div className="d-flex flex-column col">
              <label className="p-3 radio">
                <input
                    type="radio"
                    name={FIELD_NAME}
                    onClick={this.save}
                    value="Ja"
                    defaultChecked={currentValue === 'Ja'}
                />
                <div className="px-3 py-2 rounded bg-white border text-center">Ja</div>
              </label>
            </div>
            <div className="d-flex flex-column col">
              <label className="p-3 radio">
                <input
                    type="radio"
                    name={FIELD_NAME}
                    onClick={this.save}
                    value="Nein"
                    defaultChecked={currentValue === 'Nein'}
                />
                <div className="px-3 py-2 rounded bg-white border text-center">Nein</div>
              </label>
            </div>
          </div>
        </div>
    );
  }
}

export default Step5;

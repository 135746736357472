import React, { Component } from 'react';

class Step6 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      street: props.store.street,
      housenumber: props.store.housenumber,
      place: props.store.place,
      state: props.store.state,
      touchedFields: {},
    };

    this.save = this.save.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.getErrors = this.getErrors.bind(this);
    this.touchField = this.touchField.bind(this);
  }

  getErrors() {
    const {
      street, housenumber, place, state, touchedFields,
    } = this.state;
    const errors = {};


    if (touchedFields.street) {
      if (!street) {
        errors.street =  <div className="invalid-feedback"><b>Straße</b> ist ein Pflichtfeld.</div>;
      }
    }
    if (touchedFields.housenumber) {
      if (!housenumber) {
        errors.housenumber =  <div className="invalid-feedback"><b>Hausnummer</b> ist ein Pflichtfeld.</div>;
      }
    }
    if (touchedFields.place) {
      if (!place) {
        errors.place = <div className="invalid-feedback"><b>Ort</b> ist ein Pflichtfeld.</div>;
      }
    }
    if (touchedFields.state) {
      if (!state) {
        //errors.state = 'State is required';
      }
    }


    return errors;
  }

  isValidated() {
    return new Promise((resolve, reject) => this.setState({
      touchedFields: {
        street: true,
        housenumber: true,
        place: true,
        state: true,
      },
    }, () => {
      const errors  = this.getErrors();
      return Object.keys(errors).length === 0 ? resolve() : reject();
    }));
  }

  touchField(e) {
    const { name } = e.target;
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [name]: true,
      },
    });
  }

  save(e)  {
    const { value, name } = e.target;
    this.setState({ [name]: value }, () => this.props.updateStore({ [name]: value }));
  }

  render() {
    const {
      street, housenumber, place, state,
    } = this.state;

    const errors = this.getErrors();

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Wo ist Dein Shop?</h4>
        <div className="row">
            <label className="py-2 py-lg-3 col-12 col-lg-6">
              <input
                type="text"
                className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                name="street"
                placeholder="Straße"
                onChange={this.save}
                onBlur={this.touchField}
                defaultValue={street}
              />
              {errors.street && <div className="invalid-feedback">{errors.street}</div>}
            </label>
            <label className="py-2 py-lg-3 col-12 col-lg-6">
              <input
                type="text"
                className={`form-control ${errors.housenumber ? 'is-invalid' : ''}`}
                name="housenumber"
                placeholder="Hausnummer"
                onChange={this.save}
                onBlur={this.touchField}
                defaultValue={housenumber}
              />
              {errors.housenumber && <div className="invalid-feedback">{errors.housenumber}</div>}
            </label>
            <label className="py-2 py-lg-3 col-12 col-lg-6">
              <input
                type="text"
                className={`form-control ${errors.place ? 'is-invalid' : ''}`}
                name="place"
                placeholder="Ort"
                onChange={this.save}
                onBlur={this.touchField}
                defaultValue={place}
              />
              {errors.place && <div className="invalid-feedback">{errors.place}</div>}
            </label>
            <label className="py-2 py-lg-3 col-12 col-lg-6">
              <input
                type="text"
                className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                name="state"
                placeholder="Bundesland"
                onChange={this.save}
                onBlur={this.touchField}
                defaultValue={state}
              />
              {errors.state && <div className="invalid-feedback">{errors.state}</div>}
            </label>
        </div>
      </div>
    );
  }
}

export default Step6;

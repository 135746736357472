import React, { Component } from 'react';

class Brands extends Component {

    render() {
        return (
            <div className="border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 mx-auto d-flex flex-column flex-md-row justify-content-between align-items-center">
                            <img src={require('../../static/logo-welt-n24.png')} alt="Logo Welt / N24" role="presentation" className="m-3 img-fluid" />
                            <img src={require('../../static/logo-bild.png')} alt="Logo Bild" role="presentation" className="m-3 img-fluid" />
                            <img src={require('../../static/logo-tagesspiegel.svg')} alt="Logo Tagesspiegel" role="presentation" className="m-3 img-fluid" />
                            <img src={require('../../static/logo-focus.svg')} alt="Logo Focus Online" role="presentation" className="m-3 img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Brands;
import React, { Component } from 'react';

class Steplist extends Component {
    render() {
        return (
        	<div className="container py-3 my-5">
        		<h3 className="text-center pb-5">So funktioniert's</h3>
           		<div className="d-flex flex-column flex-md-row align-items-start">
					<div className="col-12 col-md-4 d-flex justify-content-center align-items-center flex-column">
						<div className="rounded-circle p-4 bg-light d-flex justify-content-center align-items-center">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.99 48" style={{fill: '#007acf', minWidth: 50}}>
								<path d="M47,41.45,34.65,29.07a4.16,4.16,0,0,0-.39-.32A18.63,18.63,0,1,0,5.45,31.8a18.65,18.65,0,0,0,23.29,2.47,3.09,3.09,0,0,0,.33.39L41.44,47a3.25,3.25,0,0,0,2.32,1,3.08,3.08,0,0,0,2.2-.9L47.09,46A3.19,3.19,0,0,0,47,41.45ZM11.17,29.85l0,0Zm17-1.7s-.07.06-.1.1V18.37h-4.3V31.09a13.21,13.21,0,0,1-3,.82V8.22h-4.3V31.91a13,13,0,0,1-3-.83V13.7H9.17V28.22l-.08-.07a13.48,13.48,0,1,1,19.05,0Z"/>
							</svg>
						</div>
						<div className="mt-3 text-center">
							<p><b>1. Professionelle Onlinebewertung</b></p>
							<p>Gib uns in nur 2 Minuten die Daten zu Deinem Wettshop. Unsere Online Experten bewerten Deinen Shop kostenlos und Du erhältst von uns eine Ersteinschätzung.</p>
						</div>
					</div>
					<div className="col-12 col-md-4 d-flex justify-content-center align-items-center flex-column">
						<div className="rounded-circle p-4 bg-light d-flex justify-content-center align-items-center">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.04 48" style={{fill: '#007acf', minWidth: 50}}>
								<path d="M16.33,9.55,4,0H0V30.16l2.77-2.55a1,1,0,0,1,.83-.25L16.05,11.64Z"/>
								<path d="M10.07,36.2a1.54,1.54,0,0,0-1.22.61,1.68,1.68,0,0,0,.24,2.31,1.54,1.54,0,0,0,2.16-.27A1.68,1.68,0,0,0,11,36.54,1.49,1.49,0,0,0,10.07,36.2Z"/>
								<path d="M6.06,32.91a1.52,1.52,0,0,0-1.22.61,1.68,1.68,0,0,0,.24,2.31,1.52,1.52,0,0,0,2.15-.27A1.68,1.68,0,0,0,7,33.25,1.45,1.45,0,0,0,6.06,32.91Z"/>
								<polygon points="39.8 0 32.28 8.5 46.05 25.59 46.05 0 39.8 0"/>
									<path d="M14.08,39.49a1.52,1.52,0,0,0-1.21.61,1.67,1.67,0,0,0,.24,2.31,1.52,1.52,0,0,0,2.15-.27A1.67,1.67,0,0,0,15,39.83,1.47,1.47,0,0,0,14.08,39.49Z"/>
									<path d="M16.48,20.68c-.21.4-.88,2-.23,2.54a2.08,2.08,0,0,0,2.86.06c1-1,4.36-4.35,6.1-4.67h0a.76.76,0,0,1,.26,0h.15l.16.08.2.1,0,0,7.27,6.05h0l5.09,4.23,4.92-3.64L31.12,10.32C28.58,11.31,19.54,15.13,16.48,20.68Z"/>
									<path d="M25.19,20.79a25.8,25.8,0,0,0-4.61,3.92,4.11,4.11,0,0,1-5.66.06c-1.62-1.38-.91-3.84-.24-5.07,1.59-2.87,4.47-5.28,7.39-7.13l-4.78.8-11.86,15L26.31,45.7A1.05,1.05,0,0,0,27,46h.18a1.61,1.61,0,0,0,1.08-.62,1.74,1.74,0,0,0,.38-1.11,1.26,1.26,0,0,0-.43-1l-6.9-5.74a1,1,0,0,1,1.31-1.58l1.19,1h0l5.71,4.76h0l1.19,1a1.1,1.1,0,0,0,.85.24,1.64,1.64,0,0,0,1.07-.61,1.53,1.53,0,0,0-.05-2.07l-6.9-5.75a1,1,0,0,1-.14-1.44A1,1,0,0,1,27,32.92l.59.49,6.8,5.66.1.09.14.08.09.06.11,0,.11,0a1.41,1.41,0,0,0,.4,0,1.57,1.57,0,0,0,1.07-.62,1.52,1.52,0,0,0,0-2.07L29.42,31h0l-.59-.5a1,1,0,1,1,1.31-1.57L37,34.65h0a1,1,0,0,0,.66.24h.19A1.61,1.61,0,0,0,39,34.27a1.53,1.53,0,0,0,0-2.07Z"/>
									<path d="M18.1,42.78a1.52,1.52,0,0,0-1.22.61,1.68,1.68,0,0,0,.24,2.31,1.53,1.53,0,0,0,2.15-.26A1.69,1.69,0,0,0,19,43.12,1.51,1.51,0,0,0,18.1,42.78Z"/>
								<polygon points="27.02 48 27.02 48 27.02 48 27.02 48"/>
							</svg>
						</div>
						<div className="mt-3 text-center">
							<p><b>2. Kostenlose Expertenbewertung</b></p>
							<p>Wenn Du dann Deinen Shop verkaufen willst, besuchen unsere Experten Deinen Shop Vor Ort und klären Deinen Wunschpreis für den Verkauf.</p>
						</div>
					</div>
					<div className="col-12 col-md-4 d-flex justify-content-center align-items-center flex-column">
						<div className="rounded-circle p-4 bg-light d-flex justify-content-center align-items-center">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 42.77" style={{fill: '#007acf', minWidth: 50}}>
								<path d="M47.26,4.89,44,4V2.31a.26.26,0,0,0-.19-.25,75.47,75.47,0,0,0-33.27-.4l.7,3.42-.44.09,0,2.76,1,1L10.3,10.33c-.81.79-1.39,1.42-1.8,1.91v2.92a23,23,0,0,0,1.18,2.61l1.09-.25a44.06,44.06,0,0,0,1.87-7.14.94.94,0,0,1-.84-1,1.23,1.23,0,0,1,1.12-1.26,1.07,1.07,0,0,1,.49.14A4.27,4.27,0,0,1,17,6.39a4.77,4.77,0,0,1,.76.06,4.37,4.37,0,0,1,3.56,5.05c0,.16-.1.6-1.06,4.65A74.46,74.46,0,0,1,43.68,18a.25.25,0,0,0,.3-.24V6.07l2,.54V23.29c-3.36-1.78-12.21-5.38-28.19-4.93l.06,2c19.87-.56,28.46,5.44,28.54,5.5A1,1,0,0,0,47,26a.88.88,0,0,0,.45-.11A1,1,0,0,0,48,25V5.85A1,1,0,0,0,47.26,4.89Zm-21,6.53c-2.49,0-4.47-1.22-4.46-3.3a4.47,4.47,0,0,1,8.93,0C30.71,10.2,28.73,11.41,26.24,11.42ZM38.46,9.61a1,1,0,0,1-1.12-1,1.13,1.13,0,1,1,2.25,0A1,1,0,0,1,38.46,9.61Z"/>
								<path d="M5.31,30.49h9.48l0-.19s2-8.45,3.39-14.14c.7-3,1.08-4.63,1.14-5A2.35,2.35,0,0,0,19,9.38a2.38,2.38,0,0,0-1.54-1,2.35,2.35,0,0,0-1.78.4,2.39,2.39,0,0,0-1,1.54c-.66,3.78-2,8.48-2.88,9.45a4.65,4.65,0,0,1-2,1.19C8.12,20,5.65,14.41,6,12.43a14.47,14.47,0,0,1,3-3.53l-.17-.18.07-5.16,0,0-.1-.49a2.45,2.45,0,0,0-1.06.52A29.47,29.47,0,0,0,2.18,9.48,11.08,11.08,0,0,0,.07,13.26C-.68,17.65,5,29.83,5.24,30.35Z"/>
								<path d="M19.91,33H3.58a.25.25,0,0,0-.25.25v9.25a.25.25,0,0,0,.25.25H19.91a.26.26,0,0,0,.26-.25V33.27A.26.26,0,0,0,19.91,33Zm-5.2,6.67a1.8,1.8,0,1,1,1.8-1.8A1.79,1.79,0,0,1,14.71,39.69Z"/>
								<path d="M26.51,7.65c-.72-.26-1-.38-1-.74s.24-.64.86-.63a1.91,1.91,0,0,1,1,.32l.2-.42a2.12,2.12,0,0,0-1.06-.36V5.17H26v.69a1.36,1.36,0,0,0-1.23,1.23c0,.61.51.8,1.35,1.05.6.2.92.49.92.86s-.39.61-1,.63a3.27,3.27,0,0,1-1.17-.18l-.19.49a3.65,3.65,0,0,0,1.24.15v.7h.52v-.71c.85-.09,1.29-.47,1.29-1S27.35,8,26.51,7.65Z"/>
							</svg>
						</div>
						<div className="mt-3 text-center">
							<p><b>3. Verkauf Deines Shops</b></p>
							<p>Wenn Du uns beauftragst übernehmen wir die Vermarktung zu Deinem Wunschpreis. Wenn kein Verkauf stattfindet, bleibt alles für Dich kostenlos. </p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default Steplist;
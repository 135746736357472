import React, { Component } from 'react';

class ProvenExpert extends Component {
    render() {
        return (
        	<div className="container px-0 pb-4">
				<div id="pewl" style={{ maxWidth: '100%' }} />
				<link rel="stylesheet" type="text/css" href="https://www.provenexpert.com/css/widget_landing.css" media="screen,print" />
        	</div>
        )
    }
}
export default ProvenExpert;
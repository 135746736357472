import React, { Component } from 'react'
import { Style } from 'radium';
import StepZilla from 'react-stepzilla'
import ReactGA from 'react-ga';
import Step0 from './pages/Step0';
import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Step4 from './pages/Step4';
import Step5 from './pages/Step5';
import Step6 from './pages/Step6';
import Step7 from './pages/Step7';
import Step8 from './pages/Step8';

const wrapperStyles = {
  height: '100vh',
  width: '100vw',
  overflow: 'hidden',

  '#wizard-wrapper': {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.95)',
    zIndex: '1000',
  },

  '#wizard-wrapper #wizard-container': {
    overflow: 'auto',
  }
};

const wizard = {
  minHeight: '40vh',
  background: '#E3F1FA',

  '#prev-button': {
    display: 'none',
  },
  '.invalid-feedback': {
    display: 'block',
  },
  '.multi-step': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  '.multi-step > .step': {
    minHeight: '250px',
  },
  '.progtrckr': {
    display: 'flex',
    justifyContent: 'stretch',
    margin: '-16px -16px 10px -16px',
    padding: 0,
    background:'#6c757d'
  },
  '.progtrckr > li': {
    display: 'block',
    flex: 1,
    padding: 4,
    transition: 'all .5s',
  },
  '.progtrckr > li.progtrckr-doing, .progtrckr > li.progtrckr-done': {
    background: '#2186c3',
    borderRight: '1px solid #fff',
  },
  '.progtrckr > li > span, .progtrckr > li > em': {
    display: 'none',
  },
  '.footer-buttons': {
    textAlign: 'center',
  },
  'label.radio > input': {
    display: 'none',
  },
  'label.radio input:checked + div, label.radio:hover > div': {
    background: '#2186c3!important',
    color: '#fff',
    cursor: 'pointer',
  }
};


class Wizard extends Component {
  constructor(props) {
    super(props);

    const currentStep = window.sessionStorage.getItem('step') ? parseFloat(window.sessionStorage.getItem('step')) : 0;
    const store = window.sessionStorage.getItem('store') ? JSON.parse(window.sessionStorage.getItem('store')) : {};
    this.state = {
      currentStep,
      store,
    };

    this.updateStore = this.updateStore.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.resetWizard = this.resetWizard.bind(this);
  }

  handleStep(step) {
    ReactGA.modalview('/wizard/step-' + step);
    this.setState({ currentStep: step }, () => window.sessionStorage.setItem('step', step));
  }

  updateStore(update) {
    const newStore = {
      ...this.state.store,
      ...update,
    };
    this.setState({ store: newStore }, () => window.sessionStorage.setItem('store', JSON.stringify(newStore)));
  }

  resetWizard() {
    this.setState({
      currentStep: 0,
      store: {},
    }, () => {
      window.sessionStorage.removeItem('step');
      window.sessionStorage.removeItem('store');
      this.props.toggleWizard();
    });
  }

  render() {
    const { currentStep, store } = this.state;
    if(currentStep === 0) { ReactGA.modalview('/wizard/step-0'); }
    
    const steps = [
      {name: 'Step 0', component: <Step0 store={store} updateStore={this.updateStore} />},
      {name: 'Step 1', component: <Step1 store={store} updateStore={this.updateStore} />},
      {name: 'Step 2', component: <Step2 store={store} updateStore={this.updateStore} />},
      {name: 'Step 3', component: <Step3 store={store} updateStore={this.updateStore} />},
      {name: 'Step 4', component: <Step4 store={store} updateStore={this.updateStore} />},
      {name: 'Step 5', component: <Step5 store={store} updateStore={this.updateStore} />},
      {name: 'Step 6', component: <Step6 store={store} updateStore={this.updateStore} />},
      {name: 'Step 7', component: <Step7 store={store} updateStore={this.updateStore} />},
      {name: 'Step 8', component: <Step8 store={store} resetWizard={this.resetWizard} />},
    ];

    return (
      <div  id="wizard-wrapper" className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
        <Style scopeSelector="body" rules={wrapperStyles} />
        <div className="container" id="wizard-container">
          <div className="p-0 border d-flex flex-column col-12 col-md-8 col-lg-8 mx-auto">
            <div className="border-bottom text-left text-md-center p-3 text-muted" style={{position: 'relative'  }}>
              <div className="d-md-none px-2 px-md-3 small text-truncate">Kostenlos & unverbindlich anfragen</div>
              <div className="d-none d-md-block px-5">Kostenlos & unverbindlich anfragen</div>
              <div style={{ position: 'absolute', top: '50%', transform: 'translatey(-50%)', right: '10px' }}>
                <button onClick={this.resetWizard} className="btn btn-link">
                  <img src={require('../../static/squared-cross.svg')} width="24" alt="Bereich schließen" />
                </button>
              </div>
            </div>
            <div className="d-flex flex-column p-3 flex-grow-1" id="wizard">
              <Style scopeSelector="#wizard" rules={wizard} />
              <div className='step-progress d-flex flex-grow-1'>
                <StepZilla
                  steps={steps}
                  startAtStep={currentStep}
                  onStepChange={this.handleStep}
                  nextButtonCls="btn btn-next btn-primary btn-lg"
                  backButtonCls="btn btn-prev btn-secondary btn-lg"
                  // backButtonText="Zurück"
                  nextButtonText="Weiter"
                  nextTextOnFinalActionStep={"Absenden"}
                  showNavigation={[3, 4, 6, 7, 8].includes(currentStep)}
                  stepsNavigation={false}
                  showSteps={currentStep !== 8}
                />
              </div>
            </div>
            <div className="p-3 d-flex flex-column small text-muted text-center">
              <div>Ihre Daten werden <b>stets verschlüsselt</b> und nie an Dritte weiter gegeben</div>
              <div className="text-center d-flex justify-content-center flex-wrap">
                <img src={require('../../static/ProvenExpert.png')} alt="Proven Expert" style={{height: '17px'}} className="mt-3 mx-3" />
                <img src={require('../../static/SSLSecured.png')} alt="SSL Secured" style={{height: '17px'}} className="mt-3 mx-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Wizard;

import React, { Component } from 'react';

const FIELD_NAME = 'space';

class Step3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.store[FIELD_NAME],
      touched: false,
    };

    this.save = this.save.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.touchField = this.touchField.bind(this);
    this.getError = this.getError.bind(this);
  }

  getError() {
    const { currentValue, touched } = this.state;

    if (touched && (!currentValue || currentValue < 0)) {
      return <div className="invalid-feedback">Wie groß ist die Ladenfläche? Schätzwerte sind okay.</div>;
    }

    return null;
  }

  isValidated() {
    return new Promise((resolve, reject) => this.setState({
      touched: true,
    }, () => (!this.getError() ? resolve() : reject())));
  }

  touchField() {
    this.setState({ touched: true });
  }

  save(e)  {
    const { value } = e.target;
    this.setState({ currentValue: value }, () => this.props.updateStore({ [FIELD_NAME]: value }));
  }

  render() {
    const { currentValue } = this.state;

    const error = this.getError();

    return (
      <div className="d-flex flex-column flex-grow-1 step">
        <h4 className="text-center mb-4 mt-2">Wieviel Nutzfläche hat dein Shop?</h4>
        <div className="d-flex justify-content-around">
          <label>
            <div className="input-group">
              <input
                type="number"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                name={FIELD_NAME}
                onChange={this.save}
                onBlur={this.touchField}
                defaultValue={currentValue}
              />
              <div className="input-group-append">
                <div className="input-group-text">qm</div>
              </div>
            </div>
            {error && <div className="invalid-feedback">{error}</div>}
          </label>
        </div>
      </div>
    );
  }
}

export default Step3;

import React, { Component } from 'react';

class Checklist extends Component {

    render() {
        return (
            <div className="border-top border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center col-12 col-md-6 col-xl-auto py-3">
                            <img src={require('../../static/check.svg')} width="30" className="mr-3" alt="" />
                            <div>Kostenlose Bewertung</div>
                        </div>

                        <div className="d-flex align-items-center col-12 col-md-6 col-xl-auto py-3">
                            <img src={require('../../static/check.svg')} width="30" className="mr-3" alt="" />
                            <div>Preise über Marktdurchschnitt</div>
                        </div>

                        <div className="d-flex align-items-center col-12 col-md-6 col-xl-auto py-3">
                            <img src={require('../../static/check.svg')} width="30" className="mr-3" alt="" />
                            <div>Hohes Expertenwissen</div>
                        </div>

                        <div className="d-flex align-items-center col-12 col-md-6 col-xl-auto py-3">
                            <img src={require('../../static/check.svg')} width="30" className="mr-3" alt="" />
                            <div>100% kostenloser Service</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Checklist;